import { createGlobalStyle } from "styled-components"
import { dark, light } from "../theme"

const GlobalStyles = createGlobalStyle`
    * {
        box-sizing: border-box;
    }

    html {
        /* background by SVGBackgrounds.com */
        background-image: #fff;
        background-size: 100%;
        overflow-y: scroll;
        background-color: var(--backgroundColor)
    }

    body {
        font-family: Montserrat, Raleway, Sans-Serif;
    }

    a {
        text-decoration: none;
        color: var(--primary)
    }

    img {
        height: 250px;
        display: inline-block;
    }
`
export default GlobalStyles
