const light = {
  backgroundHideColor: "rgba(256, 256, 256, 0.4)",
  boxShadowColor: "rgba(0, 0, 0, 0.044)",
  primary: "#212121",
  primaryVariant: "#424242",
  primaryLight: "#E0E0E0",
  primaryLightVariant: "#EEEEEE",
  backgroundColor: "rgba(256, 256, 256, 1)",
  lightBorderColor: "rgba(121, 121, 121, 0.5)",
  layoutBorderColor: "#ffffff",
  border: "1px solid rgba(121, 121, 121, 0.5)",
  linkColor: "#36b2cf",
}

const dark = {
  backgroundHideColor: "rgba(256, 256, 256, 0.4)",
  boxShadowColor: "rgba(256, 256, 256, 0.0)",
  primary: "#eeeeee",
  primaryVariant: "#e0e0e0",
  primaryLight: "#212121",
  primaryLightVariant: "#212121",
  backgroundColor: "rgba(0, 0, 0, 1)",
  lightBorderColor: "rgba(121, 121, 121, 0.5)",
  layoutBorderColor: "#212121",
  border: "1px solid rgba(121, 121, 121, 0.9)",
  linkColor: "#7eadbd",
}

export { dark, light }
