import React from "react"
import { Link } from "gatsby"
import styled from "styled-components"

import { FaFile } from "react-icons/fa"

const SearchDocumentContainer = styled(Link)`
  color: var(--primaryVariant);
  height: 50px;
  &:not(:last-child) {
    border-bottom: 1px solid rgba(121, 121, 121, 0.5);
  }
  display: flex;
  justify-content: start;
  align-items: center;
  word-wrap: normal;

  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
`

const SearchDocumentIcons = styled(FaFile)`
  color: var(--primaryVariant);
  margin-right: 1rem;
  flex-shrink: 0;
`

export default function SearchDocument({ article }) {
  return (
    <SearchDocumentContainer to={article.frontmatter.slug}>
      <SearchDocumentIcons /> <span>{article.frontmatter.name}</span>
    </SearchDocumentContainer>
  )
}
