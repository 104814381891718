import React from "react"
import { graphql, useStaticQuery } from "gatsby"
import Img from "gatsby-image"

const DarkLogo = () => {
  const { file } = useStaticQuery(graphql`
    query {
      file(relativePath: { eq: "ccLogoDark.png" }) {
        childImageSharp {
          fixed(width: 40, height: 40) {
            ...GatsbyImageSharpFixed
          }
        }
      }
    }
  `)

  return <Img fixed={file.childImageSharp.fixed} />
}

export default DarkLogo
