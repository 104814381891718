import React, { useContext } from "react"
import styled from "styled-components"
import "normalize.css"
import { Helmet } from "react-helmet"

import GlobalStyles from "../styles/GlobalStyles"
import Typography from "../styles/Typography"
import Nav from "../components/Nav"
import ThemeContext from "../components/ThemeContext"

import favicon from "../assets/images/logo.ico"

const SiteBorderStyles = styled.div`
  max-width: 1000px;
  margin: 0 auto 4rem auto;
  background-color: var(--backgroundColor);
  padding: clamp(10px, 1vw, 25px);
`

export default function Layout({ children }) {
  const [colorMode] = useContext(ThemeContext)

  return (
    <>
      <GlobalStyles theme={colorMode} />
      <Typography />
      <Helmet>
        <meta charSet="utf-8" />
        <title>Candlewood Docs</title>
        <link rel="icon" type="image/png" href={favicon} sizes="16x16" />
        <link rel="canonical" href="https://docs.candlewoodchurch.org" />
      </Helmet>
      <SiteBorderStyles>
        <Nav />
        {children}
      </SiteBorderStyles>
    </>
  )
}
