export default function getFolders(nodes) {
  const folder = new Set()

  nodes.forEach(({ frontmatter }) => {
    const { breadcrumb } = frontmatter

    breadcrumb.forEach((bc, index) => {
      if (index + 1 === breadcrumb.length) return

      folder.add(bc.slug)
    })
  })

  return folder
}
