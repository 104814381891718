import React from "react"
import Layout from "./src/components/Layout"
import { SearchProvider } from "./src/components/SearchContext"
import { ThemeProvider } from "./src/components/ThemeContext"
import { DocFontSizeProvider } from "./src/components/DocFontSizeContext"

export function wrapPageElement({ element, props }) {
  return <Layout {...props}>{element}</Layout>
}

export function wrapRootElement({ element }) {
  return (
    <ThemeProvider>
      <DocFontSizeProvider>
        <SearchProvider>{element}</SearchProvider>
      </DocFontSizeProvider>
    </ThemeProvider>
  )
}
