import React from "react"
import { Link } from "gatsby"
import styled from "styled-components"

import { FaFolder } from "react-icons/fa"

const SearchFolderContainer = styled(Link)`
  color: var(--primaryVariant);
  width: 100%;
  height: 50px;
  border-bottom: 1px solid rgba(121, 121, 121, 0.5);
  display: flex;
  justify-content: start;
  align-items: center;

  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;

  &:not(:last-child) {
    border-bottom: 1px solid rgba(121, 121, 121, 0.5);
  }
`

const SearchFolderIcons = styled(FaFolder)`
  color: var(--primaryVariant);
  margin-right: 1rem;
  flex-shrink: 0;
`

export default function SearchFolder({ folder }) {
  const folderSplit = folder.split("/").join(" / ")

  return (
    <SearchFolderContainer to={folder}>
      <SearchFolderIcons />
      {folderSplit}
    </SearchFolderContainer>
  )
}
