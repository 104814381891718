import React, { useContext, useState } from "react"
import { graphql, Link, useStaticQuery } from "gatsby"
import styled from "styled-components"
import { FaTimesCircle, FaSun, FaMoon } from "react-icons/fa"

import SearchContext from "../components/SearchContext"
import SearchFolder from "../components/SearchFolder"
import SearchDocument from "../components/SearchDocument"
import LightLogo from "../components/LightLogo"
import DarkLogo from "../components/DarkLogo"
import OptionsDrawer from "./OptionsDrawer"

import ThemeContext from "../components/ThemeContext"

import getFolders from "../utils/getFolders"

const NavStyles = styled.nav`
  width: 100%;
  display: grid;
  grid-template-columns: 1fr 9fr 1fr;
  position: relative;
  column-gap: 10px;
`

const LogoContainer = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;

  user-select: none; /* standard syntax */
  -webkit-user-select: none; /* webkit (safari, chrome) browsers */
  -moz-user-select: none; /* mozilla browsers */
  -khtml-user-select: none; /* webkit (konqueror) browsers */
  -ms-user-select: none; /* IE10+ */
`

const SearchContainer = styled.div`
  width: 100%;
  height: 100%;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  z-index: 5;
  font-size: var(--doc-font-size);
`

const SearchBar = styled.input`
  width: 100%;
  height: 75%;
  padding: 0 0.5rem;
  border: var(--border);
  transform: translateY(-2px);
  border-radius: 5px;
  z-index: 4;
  background-color: var(--backgroundColor);
  color: var(--primary);
`

const SearchResultPopOver = styled.div`
  width: 100%;
  position: absolute;
  top: 57px;
  left: 0;
  background-color: var(--backgroundColor);
  padding: 1rem;
  border: var(--border);
  border-radius: 5px;
  box-shadow: 2px 2px 5px 1px var(--boxShadowColor);
  overflow-y: auto;
  z-index: 4;

  font-weight: 700;
`

const BackgroundHide = styled.div`
  content: " ";
  position: absolute;
  top: 100px;
  left: 0;
  background-color: var(--backgroundHideColor);
  width: 100%;
  height: 100%;
  overflow: hidden;
  z-index: -1;
  backdrop-filter: blur(3px);
`

const StyledFaTimesCircle = styled(FaTimesCircle)`
  fill: var(--primaryVariant);
  position: absolute;
  right: -2px;
  top: 41px;
  font-size: 34px;
  z-index: 10;
  background: var(--backgroundColor);
  border-radius: 50%;
`

const LightDarkContainer = styled.div`
  height: 100%;
  width: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
  cursor: pointer;

  user-select: none; /* standard syntax */
  -webkit-user-select: none; /* webkit (safari, chrome) browsers */
  -moz-user-select: none; /* mozilla browsers */
  -khtml-user-select: none; /* webkit (konqueror) browsers */
  -ms-user-select: none; /* IE10+ */
`

const Sun = styled(FaSun)`
  font-size: 1.75rem;
  fill: var(--primaryVariant);
`

const Moon = styled(FaMoon)`
  font-size: 1.75rem;
  fill: var(--primaryVariant);
`

const Nav = () => {
  const [colorMode, setColorMode] = useContext(ThemeContext)
  const [search, setSearch] = useContext(SearchContext)
  const [searchNavOpen, setSearchNavOpen] = useState(false)
  const {
    markDownNodes: { nodes },
  } = useStaticQuery(graphql`
    query {
      markDownNodes: allMarkdownRemark(
        filter: {
          frontmatter: {
            breadcrumb: { elemMatch: { slug: { nin: "/secure" } } }
          }
        }
      ) {
        nodes {
          timeToRead
          frontmatter {
            slug
            name
            breadcrumb {
              slug
            }
          }
          html
          id
        }
      }
    }
  `)

  const folders = getFolders(nodes)
  const searchFolders = [...folders].filter(folder =>
    folder.toLowerCase().includes(search.toLowerCase())
  )

  const searchArticles = nodes.filter(
    ({ frontmatter, html }) =>
      frontmatter.name.toLowerCase().includes(search.toLowerCase()) ||
      html.toLowerCase().includes(search.toLowerCase())
  )

  return (
    <>
      <NavStyles>
        <Link to="/">
          <LogoContainer onClick={() => setSearch("")}>
            {colorMode === "dark" ? <DarkLogo /> : <LightLogo />}
          </LogoContainer>
        </Link>
        <SearchContainer className="search-container">
          <SearchBar
            className="search-container__search-bar"
            placeholder="Search"
            onChange={e => {
              setSearch(e.target.value)
            }}
            onFocus={() => {
              setSearch(search)
              setSearchNavOpen(true)
            }}
            value={search}
          ></SearchBar>
          {searchNavOpen ? (
            <>
              <StyledFaTimesCircle onClick={() => setSearchNavOpen(false)} />
              <BackgroundHide />
              <SearchResultPopOver
                className="search-container__search-results"
                size={searchFolders.length + searchArticles.length}
                onMouseUp={() =>
                  setTimeout(() => {
                    setSearchNavOpen(false)
                  }, 15)
                }
              >
                <div>
                  {searchFolders.map(folder => (
                    <SearchFolder key={folder} folder={folder} />
                  ))}
                </div>
                {searchFolders.length ? (
                  <>
                    <br />
                    <br />
                    <br />
                  </>
                ) : null}
                <div>
                  {searchArticles.map(article => (
                    <SearchDocument
                      key={article.id}
                      article={article}
                      onMouseUp={() =>
                        setTimeout(() => {
                          setSearchNavOpen(false)
                        }, 15)
                      }
                    />
                  ))}
                </div>
              </SearchResultPopOver>
            </>
          ) : null}
        </SearchContainer>
        <LightDarkContainer
          onClick={() => {
            setColorMode(colorMode === "light" ? "dark" : "light")
          }}
        >
          {colorMode === "light" ? <Moon /> : <Sun />}
        </LightDarkContainer>
      </NavStyles>
    </>
  )
}

export default Nav
