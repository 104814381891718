import React, { createContext, useEffect, useState } from "react"

const DocFontSizeContext = createContext()

export function DocFontSizeProvider({ children }) {
  const [docFontSize, setDocFontSize] = useState(undefined)

  useEffect(() => {
    const root = window.document.documentElement
    const initialFontSize = root.style.getPropertyValue("--initial-font-size")
    console.log(`initialFontSize is ${initialFontSize}`)
    setDocFontSize(parseInt(initialFontSize, 10))
  }, [])

  useEffect(() => {
    // Persist it on update
    window.localStorage.setItem("font-size", `${docFontSize}`)
    window.localStorage.setItem("line-height", `${docFontSize * 1.4}`)

    const root = window.document.documentElement

    root.style.setProperty(`--doc-font-size`, `${docFontSize}px`)
    root.style.setProperty(`--doc-line-height`, `${docFontSize * 1.4}px`)
    root.style.setProperty(`--doc-header-size`, `${docFontSize * 1.5}px`)
  }, [docFontSize])

  return (
    <DocFontSizeContext.Provider value={[docFontSize, setDocFontSize]}>
      {children}
    </DocFontSizeContext.Provider>
  )
}

export default DocFontSizeContext
