import React, { useEffect, useRef, useState, useContext } from "react"
import styled from "styled-components"
import {
  FaTimesCircle,
  FaSun,
  FaMoon,
  FaBars,
  FaPlus,
  FaMinus,
} from "react-icons/fa"

import ThemeContext from "./ThemeContext"
import DocFontSizeContext from "./DocFontSizeContext"

const OptionsDrawer = styled.div`
  position: relative;
  display: flex;
  justify-content: center;
  align-items: center;
  padding: 4px;

  user-select: none; /* standard syntax */
  -webkit-user-select: none; /* webkit (safari, chrome) browsers */
  -moz-user-select: none; /* mozilla browsers */
  -khtml-user-select: none; /* webkit (konqueror) browsers */
  -ms-user-select: none; /* IE10+ */
`

const OptionsDrawerContainer = styled.div`
  content: "";
  background-color: var(--backgroundColor);
  position: absolute;
  top: 50px;
  right: 0;
  height: 295px;
  width: 150px;
  border: var(--border);
  border-radius: 5px;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  align-items: center;
  z-index: 100;
`

const OptionsOpenIcon = styled(FaBars)`
  font-size: 30px;
  fill: var(--primaryVariant);
`

const OptionsCloseIcon = styled(FaTimesCircle)`
  font-size: 30px;
  fill: var(--primaryVariant);
`

const LightDarkContainer = styled.div`
  height: 75px;
  width: 75px;
  display: flex;
  justify-content: center;
  align-items: center;
  border-bottom: var(--border);
  cursor: pointer;
`

const Sun = styled(FaSun)`
  font-size: 1.75rem;
  fill: var(--primaryVariant);
`

const Moon = styled(FaMoon)`
  font-size: 1.75rem;
  fill: var(--primaryVariant);
`

const FontSizeContainer = styled.div`
  height: 205px;
  width: 100%;
  padding: 13px;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  align-items: center;
  color: var(--primaryVariant);
`
const FontSizeLarge = styled.div`
  height: 33%;
  width: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
  cursor: pointer;
  padding-top: 12px;
`

const FontSizeRegular = styled.div`
  height: 33%;
  width: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
  cursor: pointer;
`

const FontSizeSmall = styled.div`
  height: 33%;
  width: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
  cursor: pointer;
  padding-bottom: 9px;
`

function useOutsideClick(ref, setOptionsDrawer) {
  useEffect(() => {
    function handleClickOutside(event) {
      if (ref.current && !ref.current.contains(event.target)) {
        setOptionsDrawer(false)
      }
    }

    // Bind the event listener
    document.addEventListener("mousedown", handleClickOutside)
    return () => {
      // Unbind the event listener on clean up
      document.removeEventListener("mousedown", handleClickOutside)
    }
  }, [ref])
}

const OptionsDrawerComponent = () => {
  const [optionsDrawer, setOptionsDrawer] = useState(false)
  const [colorMode, setColorMode] = useContext(ThemeContext)
  const [docFontSize, setDocFontSize] = useContext(DocFontSizeContext)
  const wrapperRef = useRef(null)
  useOutsideClick(wrapperRef, setOptionsDrawer)

  return (
    <OptionsDrawer
      onClick={() => setOptionsDrawer(prevState => !prevState)}
      ref={wrapperRef}
    >
      {optionsDrawer ? <OptionsCloseIcon /> : <OptionsOpenIcon />}
      {optionsDrawer && (
        <OptionsDrawerContainer
          onClick={e => {
            e.stopPropagation()
          }}
        >
          <LightDarkContainer
            onClick={() => {
              setColorMode(colorMode === "light" ? "dark" : "light")
            }}
          >
            {colorMode === "light" ? <Moon /> : <Sun />}
          </LightDarkContainer>
          <FontSizeContainer>
            <strong>Font Size</strong>
            <FontSizeLarge
              onClick={() => {
                setDocFontSize(24)
              }}
            >
              Large
            </FontSizeLarge>
            <FontSizeRegular
              onClick={() => {
                setDocFontSize(16)
              }}
            >
              Regular
            </FontSizeRegular>
            <FontSizeSmall
              onClick={() => {
                setDocFontSize(12)
              }}
            >
              Small
            </FontSizeSmall>
          </FontSizeContainer>
        </OptionsDrawerContainer>
      )}
    </OptionsDrawer>
  )
}

export default OptionsDrawerComponent
