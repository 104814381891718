import React, { useState, useEffect, createContext } from "react"
import { dark, light } from "../theme"

const ThemeContext = createContext()

export function ThemeProvider({ children }) {
  const [colorMode, setColorMode] = useState(undefined)

  useEffect(() => {
    const root = window.document.documentElement
    const initialColorValue = root.style.getPropertyValue(
      "--initial-color-mode"
    )
    setColorMode(initialColorValue)
  }, [])

  useEffect(() => {
    // Persist it on update
    window.localStorage.setItem("color-mode", colorMode)

    const root = window.document.documentElement

    Object.keys(light).forEach(key => {
      root.style.setProperty(
        `--${key}`,
        colorMode === "light" ? light[key] : dark[key]
      )
    })
  }, [colorMode])

  return (
    <ThemeContext.Provider value={[colorMode, setColorMode]}>
      {children}
    </ThemeContext.Provider>
  )
}

export default ThemeContext
